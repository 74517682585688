///
/// @file global/_variables.scss
///
/// \brief Variables
///
/// These are universally needed files that are SCSS-specific. Most of the
/// following do not directly result in rendered CSS.
/// @setup change all for new brand
// Layout
$max-width: 1280px !default;
$filter-block-width: 1440px !default;
$header-height: 60px !default; // used to offset anchors, etc.
$layout_height: 200px; // used to offset anchors, etc.
// Max widths for formatters and content containers
$container-max-width: 1920px !default;
$container-max-width-landscape: 1440px !default;
// Typography definitions from La Mer 2022 Figma (work & co)
$typestyles: (
  mobile: (
    'b1': (
      'font-size': 14,
      'line-height': 22.5,
      'letter-spacing': 1%,
      'font-weight': 400
    ),
    'b2': (
      'font-size': 14,
      'line-height': 21,
      'letter-spacing': 'normal',
      'font-weight': 400
    ),
    'b3': (
      'font-size': 14,
      'line-height': 21,
      'letter-spacing': 1%,
      'font-weight': 400
    ),
    'h1': (
      'font-size': 42,
      'line-height': 42,
      'letter-spacing': -5%,
      'font-weight': 600
    ),
    'h2': (
      'font-size': 42,
      'line-height': 42,
      'letter-spacing': -5%,
      'font-weight': 600
    ),
    'h3': (
      'font-size': 42,
      'line-height': 42,
      'letter-spacing': -5%,
      'font-weight': 900
    ),
    'h4': (
      'font-size': 32,
      'line-height': 32,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h5': (
      'font-size': 22,
      'line-height': 24.2,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h6': (
      'font-size': 26,
      'line-height': 28.6,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h7': (
      'font-size': 20,
      'line-height': 24,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h8': (
      'font-size': 13,
      'line-height': 20.8,
      'letter-spacing': 5%,
      'font-weight': 400
    ),
    'h9': (
      'font-size': 13,
      'line-height': 20.8,
      'letter-spacing': 5%,
      'font-weight': 500
    ),
    'h10': (
      'font-size': 12,
      'line-height': 19.2,
      'letter-spacing': 5%,
      'font-weight': 400
    )
  ),
  desktop: (
    'b1': (
      'font-size': 15,
      'line-height': 22.5,
      'letter-spacing': 1%,
      'font-weight': 400
    ),
    'b2': (
      'font-size': 15,
      'line-height': 22.5,
      'letter-spacing': 'normal',
      'font-weight': 400
    ),
    'b3': (
      'font-size': 12,
      'line-height': 18,
      'letter-spacing': 1%,
      'font-weight': 400
    ),
    'h1': (
      'font-size': 120,
      'line-height': 120,
      'letter-spacing': -5%,
      'font-weight': 600
    ),
    'h2': (
      'font-size': 82,
      'line-height': 82,
      'letter-spacing': -5%,
      'font-weight': 600
    ),
    'h3': (
      'font-size': 52,
      'line-height': 52,
      'letter-spacing': -5%,
      'font-weight': 900
    ),
    'h4': (
      'font-size': 40,
      'line-height': 40,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h5': (
      'font-size': 26,
      'line-height': 28.6,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h6': (
      'font-size': 28,
      'line-height': 30.8,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h7': (
      'font-size': 21,
      'line-height': 25.2,
      'letter-spacing': -3%,
      'font-weight': 900
    ),
    'h8': (
      'font-size': 14,
      'line-height': 22.4,
      'letter-spacing': 5%,
      'font-weight': 500
    ),
    'h9': (
      'font-size': 14,
      'line-height': 22.4,
      'letter-spacing': 5%,
      'font-weight': 400
    ),
    'h10': (
      'font-size': 12,
      'line-height': 19.2,
      'letter-spacing': 5%,
      'font-weight': 400
    )
  )
);
$base-mobile-font-size: 15px;
$base-mobile-line-height: 1.5;
$base-font-size: 15px;
$base-line-height: 1.5;
$base-letter-spacing: 0.01em;
$base-header-line-height: $base-line-height;

// Colors (http://sachagreif.com/sass-color-variables/)
$advent-calendar-tile-color: #002640;
$black: #000000 !default;
$white: #ffffff !default;
$gray: #eeeeee !default;
$color-black: $black !default;
$color-white: $white !default;
$color-gray: #7d7d7d !default;
$color-grey: $color-gray !default;
$color-neutral-grey: #737373 !default;
$color-almost-white-gray: #fafafa !default;
$color-very-light-gray: #f4f4f4 !default;
$color-light-gray: #b0b0b0 !default;
$color-light-grey: $color-light-gray !default;
$color-dark-gray: #1b1b1b !default;
$color-dark-grey: $color-dark-gray !default;
$color-medium-dark-gray: #333333 !default;
$color-medium-dark-grey: $color-dark-gray !default;
$color-medium-gray: #959595;
$color-lighter-gray: #c2c2c2 !default;
$color-lightest-grey: #d7d7d7 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-white-smoke: #f5f5f5 !default;
$color-blue: #4285f4 !default;
$color-blue-green: #006f7c !default;
$color-dark-smoke: #f2f2f2 !default;
$color-gray-light-apt: #cccccc !default;
$color-gray-light: #e3e3e3 !default;
$color-gray-medium: #b7b7b7 !default;
$color-gray-medium-lighter: #bababa !default;
$color-gray-medium-dark: #9b9b9b !default;
$color-gray-mdark: #9d9d9d !default;
$color-gray-dark: #4a4a4a !default;
$gray-medium: #b6b6b6 !default;
$color-gray-lighter: #e6e6e6 !default;
$color-gray-darker: #6a6969 !default;
$color--gray-lightest: #d0d0d0 !default;
$color-gray-lightest-apt: #eff0f0 !default;
$color-gold-engrave: #d3b95e;
$color-gray-cs-side: #f2f3f4;
$color-gold-light-engrave: #9f8169;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$color-cream: #fcf9ee;
$color-extra-light-gray: #e2e1e1 !default;
$color-border-gray: #a3a3a3;
$tick: '\2713';
$cross: '\2715';
//social login
$color-facebook: #3c5a99;
$color-vkontakte: #4a76a8;
// Brand Colors
$color-pink: #ff4661 !default;
$color-sand: #eddecd !default;
$color-light-sand: #fffaf7 !default;
$color-green: #005c3e !default;
$color-darkest-green: #001711 !default;
$color-light-green: #f5faf5 !default;
$color-salmon: #ffd3ad !default;
$color-light-blue: #f0fafc !default;
$color-light-cream: #fffaf6 !default;
// new for 2021 Creative Refresh.
$color-light-black: #303030 !default;
$color-light-dark-grey: #30303033 !default;
$color-medium-grey: #797979 !default;
$color-silver: #d6d6d6 !default;
$color-darker-gray: #666666 !default;
$color-text: $color-dark-gray !default;
$color-link: $color-dark-gray !default;
$color-link-hover: $color-green !default;
$color--success: #458745 !default;
$color-border: $color-lighter-gray;
$color-mpp-light-gray: #fcfafa;
$color-fb-blue: #4267b2;
// frontend validation variables
$color-valid: green;
$color-success: $color-valid;
$color-error: red;
$color-invalid: $color-error;
$border-color-normal: $color-lighter-gray;
$border-color-error: $color-error;
$icon-background: white;
$inline-error-background: white;
$color-red: #d80000 !default;
$color-light-red: #ff4661 !default;
$color-dark-blue: #327391 !default;
$color-aqua-blue: #30aaad !default;
$color-dark-green: #006241;
$color-success-green: #087443;
$color-background-success: #f0f9f5;
$color-dark-maroon: #a80000;
$color-light-maroon: #b54708;
$color-very-lighter-gray: #f0f0f0;
$color-box-shadow: rgba(0, 0, 0, 0.7);
$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;
$color-lite-grey: #d6d6d64d !default;
$color-lite-beige: #f5f5dc !default;

// If you have questions about why these aren't classes
// (http://www.paulirish.com/2008/conditional-stylesheets-vs-css-hacks-answer-neither/)
// It's because that method triggers compatability mode:
// https://github.com/h5bp/html5-boilerplate/issues/1187
$ie8: "html[data-useragent*='MSIE 8.0']";
$ie9: "html[data-useragent*='MSIE 9.0']";
$ie10: "html[data-useragent*='MSIE 10.0']";
$ie11: "html[data-useragent*='MSIE 11.0']";
// Compass variables
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;
// Template Colors
$tabbed-block-inactive-green: #006343;
// Default transition settings
$transition-duration: 0.3s;
$transition-effect: ease-in-out;
$content-block-max-width: 1060px !default;
// AliPay Enabled
$alipay-enabled: false !default;
//signin page redesign
$signin-redesigned: false !default;
// System colors
$color-focus: #8c8c8c;
// RTL support
$rdirection: right !default;
$ldirection: left !default;
$text-direction: ltr !default;
$is-rtl: false !default;

// Custom Kit BG color
$kit-gradient-bg: rgba(225, 225, 225, 0.3);
$square-aspect-ratio-percent: 93.75%;
$rectangle-aspect-ratio-percent: calc(#{$square-aspect-ratio-percent} / 2);
$rectangle-aspect-ratio-percent-position: calc(#{$square-aspect-ratio-percent} / -4);
// Content containers outer pad vars
$container-pad-small: 20px !default;
$container-pad-landscape: 40px !default;
// 2021 Colors
$color-primary-green: #265b3f !default;
$color-primary-beige: #efedea !default;
$color-neutral-black: #1b1b1b !default;
$color-neutral-dark-gray: #303030 !default;
$color-neutral-gray: #666666 !default;
$color-neutral-light-gray: #979797 !default;
$color-neutral-silver: #d6d6d6 !default;
$color-neutral-light-silver: #d4d4d4 !default;
$color-feedback-red: #ee0000 !default;
$color-marine: #4f94a8 !default;
$color-marine-text: #387f93 !default;
$color-aquamarine: #2780bb !default;
$color-aquamarine-text: #1e7bb6 !default;
$color-ultramarine: #053f67 !default;
$color-primary-marine: $color-marine;
$color-primary-marine-text: $color-marine-text;
$color-primary-aquamarine: $color-aquamarine;
$color-primary-aquamarine-text: $color-aquamarine-text;
$color-primary-ultramarine: $color-ultramarine;
$color-dark-grey-20: rgba(48, 48, 48, 0.2);
$color-white-80: rgba(255, 255, 255, 0.8);
$color-white-50: rgba(255, 255, 255, 0.5);
$color-white-20: rgba(255, 255, 255, 0.2);
$color-black-50: rgba(0, 0, 0, 0.5);
$color-silver-30: rgba(214, 214, 214, 0.3);
$color-silver-10: rgba(0, 0, 0, 0.1);
// Gradients
$color-gradient-setting-black: 167.06deg, #353535 5.68%, #1a1a1a 94.63%;
$color-gradient-setting-beige: 128.07deg, #fbfbfb 12.08%, #dedad3 87.51%;
$color-gradient-setting-grey: 128.07deg, #fafafa 34.87%, #e9e9eb 87.51%;
$color-gradient-setting-green: 167.06deg, #fafcfa 5.68%, #d7e0d4 94.63%;
$color-gradient-setting-light-blue: 128.07deg, #eef7f9 30.15%, #d0e0e2 87.51%;
$color-gradient-setting-light-blue-50: 145.07deg, #eef8fa 30.15%, #d0e0e2 70.51%;
$color-gradient-setting-pink: 128.07deg, #f6efef 12.08%, #d5c1c0 87.51%;
$color-gradient-setting-brown: 128.07deg, #fcebd9 12.08%, #d0ac8f 87.51%;
$color-gradient-setting-dark-green: 128.07deg, #265b3f 12.08%, #013420 87.51%;
$gradients: (
  'light-gray': $color-gradient-setting-grey,
  'light-sand': $color-gradient-setting-beige,
  'sand': $color-gradient-setting-brown,
  'light-green': $color-gradient-setting-green,
  'light-blue': $color-gradient-setting-light-blue,
  'black': $color-gradient-setting-black,
  'pink': $color-gradient-setting-pink
);
// Gnav specific vars
$gnav-max-width: $container-max-width;
$gnav-fixed-z-index: 995 !default;
$navbar-mobile-height: 80px !default;
$navbar-height: 80px !default;
$offerbar-height: 50px !default;
$offerbar-mobile-height: 40px !default;
$gnav-mobile-height: $navbar-mobile-height + $offerbar-mobile-height;
$gnav-height: $navbar-height + $offerbar-height;
$toolbar-height: 87px;
$gnav-icon--inline: 16px !default;
$gnav-mobile-hpad--outer: 22px !default;
$gnav-mobile-link-height: 50px !default;
$gnav-content-max-height: 612px !default;
$sticky-breakpoint: 1180px;
// 2021 footer refresh styles
$cr22_footer_refresh: false !default;
// Global Styles Refresh 2022
$cr22: false !default;
$hide_fb_statement: false !default;
// SPP grid image layout
$spp-grid-image: false !default;

//Lux styles
$color-medium-gray-alt: #999999;
$color-navy: #040a2b;
$fb-btn-background: rgba(59, 89, 152, 0.88);
$fb-btn-border: #5f7cbb;
$input-border-color: #4e4e4e;
$color-medium-green: #70b000;
$lux-error: #b00020; //rgba(176, 0, 32)
$lux-error-opacity-02: rgba($lux_error, 0.02); //#b0002005
$color-blue-dark: rgb(4, 10, 43);
$color-grey-text: rgb(204, 204, 204);
$color-black-opacity-88: rgba($color-black, 0.88); //#000000E0;
$lux-info-icon: #bb8b60;
$lux-success: #44826e;
$lux-success-background: #eefbf7;
$lux-success-background-opacity-60: rgba($lux-success-background, 0.6);
$lux-success-border: #58a68d;

$font-family-knockout: 'Neue Haas Unica Pro', sans-serif !default;
$header-font-family: 'La Mer Headline', serif !default;

// ELC BASE - SHARED Design enable
$elc_base_shared_checkout_designs: true !default;

// shared v3 variables
$color-classic-matte-black: #1c1c1c !default;
$color-dark-red: #cc0000 !default;
$color-dark-black: #000000 !default;
$color-forest-green: #06891e !default;
$color-green-v2: #a3d6c7 !default;
$color-navy-blue: #0075db !default;
$color-focus: #106bfe !default;
$color-grey-dark: #525252 !default;
$color-tmo-red: #da1e28 !default;

$main-font: $font-family-knockout !default;
$main-font-regular: $main-font !default;
$base-font-family: $main-font !default;
$header-font-family: $header-font-family !default;
$medium-font-family: $font-family-knockout !default;
$bold-font-family: $font-family-knockout !default;
$main-font-medium: $medium-font-family;
$light-font-family: $font-family-knockout !default;
$main-font-light: $light-font-family;

$base-theme-path: '/sites/cremedelamer2/themes/cremedelamer2_base/';
$icons-base-path: '#{$base-theme-path}/img/icons/src/';

// Enable stylings for error message for hazmat product with restricted ingredients.
$enable-restrict-ingredient-error: false !default;
