@import '../../../../scss/theme-bootstrap';

$basic-grid-gutter: 10px;

.basic-grid-formatter {
  &.content-container {
    max-width: $content-block-max-width;
    margin: 0 auto;
    @if $cr22 {
      .content-block-formatter__item & {
        @include breakpoint($landscape-up) {
          padding-#{$ldirection}: 30px;
          padding-#{$rdirection}: 30px;
        }
      }
    }
  }
  .grid-inactive-text {
    display: block;
  }
  .grid-active-text {
    display: none;
  }
  &.grid-details-active {
    .grid-inactive-text {
      display: none;
    }
    .grid-active-text {
      display: block;
    }
    .basic-grid-formatter__details-reveal {
      opacity: 1;
      top: 0;
      visibility: visible;
    }
    .basic-grid-formatter__details-copy {
      opacity: 1;
    }
  }
  &__details {
    text-align: center;
  }
  &__details-trigger {
    display: inline-block;
  }
  &__details-reveal {
    visibility: hidden;
    top: 100%;
    transition: all 0.3s;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: $color-white;
    padding: 15px;
    max-width: $content-block-max-width;
    margin: auto;
  }
  &__details-copy {
    opacity: 0;
    transition: all 0.3s;
  }
  &--borders {
    @include breakpoint($medium-up) {
      .basic-grid {
        border: 1px solid $gray;
        padding: 40px 0;
      }
      .basic-grid__item {
        padding: 0 40px;
        border-right: 1px solid $gray;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

.basic-grid {
  display: flex;
  flex-wrap: wrap;
  @include breakpoint($landscape-up) {
    flex-wrap: nowrap;
  }
  padding-right: $basic-grid-gutter;
}

.basic-grid__item {
  padding: 0 0 $basic-grid-gutter $basic-grid-gutter;
  @include breakpoint($small-down) {
    .basic-grid--small-cols-1 & {
      flex: 1 1 100%;
      padding: 0 0 $basic-grid-gutter 0;
    }
    .basic-grid--small-cols-2 & {
      flex: 1 1 50%;
    }
    .basic-grid--small-cols-3 & {
      flex: 1 1 33.333%;
    }
    .basic-grid--small-cols-4 & {
      flex: 1 1 25%;
    }
  }
  @include breakpoint($medium-up) {
    .basic-grid--medium-cols-1 & {
      flex: 1 1 100%;
      padding: 0 0 $basic-grid-gutter 0;
    }
    .basic-grid--medium-cols-2 & {
      flex: 1 1 50%;
    }
    .basic-grid--medium-cols-3 & {
      flex: 1 1 33.333%;
    }
    .basic-grid--medium-cols-4 & {
      flex: 1 1 25%;
    }
  }
}

.basic-grid--small-cols-auto,
.basic-grid--medium-cols-auto {
  display: block;
  text-align: center;
  .basic-grid__item {
    vertical-align: top;
    display: inline-block;
    width: auto;
  }
}

.basic-carousel--dots-position-active {
  // Mobile only
  .carousel-dots {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  .slick-slide .carousel-dots {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .slick-dots {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}

.disable-scrolling {
  overflow-y: hidden;
}

.expandable_video_content {
  .basic-grid__item,
  .mpp-tout--container-v2 {
    margin: 0;
    padding: 0;
  }
  .mantle-media-asset img {
    width: 100%;
  }
  .mpp-tout__icon {
    #{$rdirection}: 22px;
    color: $color-white;
    bottom: 22px;
    position: absolute;
    @include breakpoint($medium-up) {
      #{$rdirection}: 44px;
      bottom: 44px;
    }
    .icon--plus {
      display: block;
      font-size: 20px;
    }
  }
  .video__overlay-outter {
    #{$ldirection}: 0;
    background-color: $black;
    display: none;
    height: 100%;
    opacity: 0.7;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z('video-module', 'video-overlay');
  }
  .video__overlay {
    #{$ldirection}: 0;
    background-color: $color-black;
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z('video-module', 'video-overlay');
    .videojs-video {
      padding-top: 49%;
    }
    @include breakpoint($medium-up) {
      #{$ldirection}: 2%;
      height: 87%;
      top: 7%;
      width: 96%;
    }
    .videojs-video__player,
    .videojs-video__landing,
    .video-details__video-wrapper,
    .videojs-video {
      position: fixed;
      @include breakpoint($medium-up) {
        #{$ldirection}: 2%;
        height: 87%;
        padding: 0;
        top: 7%;
        width: 96%;
      }
    }
    .video__overlay-close {
      #{$rdirection}: 26px;
      color: $color-white;
      cursor: pointer;
      display: flex;
      font-size: 15px;
      position: absolute;
      top: 33px;
      z-index: z('video-module', 'close-icon');
      @include breakpoint($medium-up) {
        #{$rdirection}: 0;
        top: -33px;
      }
      &-cta {
        position: absolute;
        #{$rdirection}: 26px;
        top: 2px;
      }
      &-icon {
        @include icon('close');
      }
    }
    .video-block__media-video {
      height: auto;
      width: 100%;
    }
  }
  .expandable_video_grid {
    cursor: pointer;
  }
  .videojs-video__landing__image,
  .vjs-poster {
    // Used !important to override the inline style
    display: none !important; // stylelint-disable-line declaration-no-important
  }
}
