@function map-has-nested-keys($map, $keys...) {
  @each $key in $keys {
    @if not map-has-key($map, $key) {
      @return false;
    }
    $map: map-get($map, $key);
  }
  @return true;
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function z($layers...) {
  @if not map-has-nested-keys($z-layers, $layers...) {
    @warn "No layer found for `#{inspect($layers...)}` in $z-layers map. Property omitted.";
  }
  @return map-deep-get($z-layers, $layers...);
}

$z-layers: (
  'default': 1,
  'under': -1,
  'peak': 999999,
  'discover': (
    'bg-img-left': 2,
    'bg-img-top': 2,
    'title': 3,
    'label': 4,
    'text': 5,
    'video-frame': 6,
    'video-player': 7,
    'video-screenshot': 8,
    'close-icon': 8,
  ),
  'video-module': (
    'video-container': 0,
    'video-iframe': 1,
    'video-mini-overlay': 2,
    'video-frame': 6,
    'video-player': 7,
    'video-screenshot': 8,
    'close-icon': 10,
    'video-overlay': 999,
    'video-reactions': 9,
    'video-fullscreen': 100,
    'video-controls': 110,
  ),
  'ingredient-gallery': (
    'arrow': 5,
    'filter': 9,
    'filter-icon': 10
  )
);
