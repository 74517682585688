// This file is generated by `gulp icons`, do not modify

$icons: (
  arrow--right--small: '\E001',
  arrow--right: '\E002',
  arrow-white--up: '\E003',
  bag: '\E004',
  caret--down: '\E005',
  caret--left: '\E006',
  caret--right: '\E007',
  caret--up: '\E008',
  check: '\E009',
  checkbox--checked: '\E00A',
  checkbox: '\E00B',
  circle-caret--down--filled: '\E00C',
  circle-caret--down: '\E00D',
  circle-caret--left--filled: '\E00E',
  circle-caret--left: '\E00F',
  circle-caret--right--filled: '\E010',
  circle-caret--right: '\E011',
  circle-caret--up--filled: '\E012',
  circle-caret--up: '\E013',
  close: '\E014',
  email: '\E015',
  expert_tips: '\E016',
  facebook: '\E017',
  hamburger: '\E018',
  heart--filled: '\E019',
  heart: '\E01A',
  instagram: '\E01B',
  livechat: '\E01C',
  location--filled: '\E01D',
  location: '\E01E',
  logo: '\E01F',
  map-marker: '\E020',
  minus: '\E021',
  pinterest--square--filled: '\E022',
  pinterest--square: '\E023',
  pinterest: '\E024',
  play: '\E025',
  plus: '\E026',
  question--circle: '\E027',
  radio--checked: '\E028',
  radio: '\E029',
  search: '\E02A',
  twitter: '\E02B',
  youtube: '\E02C'
);
